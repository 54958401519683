<template>
  <div class="row header">
    <div class="mx-auto d-none d-md-block">
      <img src="/images/slider/20.jpg" class="img-fluid"/>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide 
  }
}
</script>
